const desktopIcons = [
  {
    url: "/icons/same-day-desktop.svg",
    label: "Same Day",
    width: 25,
    landingUrl: "/same-day-delivery-gifts-lp",
    id: "sameDay",
  },
  { url: "/icons/currency-icons/inr.svg", label: "INR", width: 25, id: "currency" },
  {
    url: "/icons/corporate-gift.svg",
    label: "Corporate",
    width: 25,
    landingUrl: "/corporate/?promo=corporate_enquiry",
    id: "corporate",
  },
  { url: "/icons/cart.svg", label: "Cart", width: 25, id: "cart" },
  { url: "/icons/user-square-desktop.svg", label: "Hi Guest", width: 25, id: "account" },
  { url: "/icons/more-square.svg", label: "More", width: 25, id: "moreItems" },
];

const constantText = {
  toolbar: {
    currency: "Currency",
    account: "Account",
    more: "More",
    cart: "Cart",
  },
  MEDIA: "Media",
  LOGIN_REGISTER: "Login/Register",
  LOGOUT: "Logout",
};
const mobileIconsHome = [
  { url: "/icons/currency-icons/inr.svg", label: "INR", width: 25, id: "currency" },
  { url: "/icons/cart.svg", label: "Cart", width: 25, id: "cart" },
];
const mobileIcons = [
  { url: "/icons/search-normal.svg", label: "Search", width: 25, id: "search" },
  { url: "/icons/currency-icons/inr.svg", label: "INR", width: 25, id: "currency" },
  { url: "/icons/cart.svg", label: "Cart", width: 25, id: "cart" },
];
const mediaPopUp = {
  PLEASE_MAIL_TO: "Please mail to",
  FOR_QUERY: "for media queries",
  PR_EMAIL: "pr@fnp.com",
};
const SEND_GIFT_ABROAD = "Send Gifts Abroad";
const SHOP_BY_OCCASION = "Occasion";
const SHOP_BY_CATEGORY = "Category";
const GLOBAL = "Global";
const INTERNATIONAL = "International";
const OTHER_COUNTRIES = "other countries";
const NEW = "New";

export {
  constantText,
  desktopIcons,
  mobileIcons,
  mediaPopUp,
  mobileIconsHome,
  SEND_GIFT_ABROAD,
  SHOP_BY_OCCASION,
  SHOP_BY_CATEGORY,
  GLOBAL,
  INTERNATIONAL,
  OTHER_COUNTRIES,
  NEW,
};
