"use client";
import React from "react";
import PropTypes from "prop-types";

/**
 * A component representing a list of tabs.
 *
 * @component
 * @param {object} props - The props of the component.
 * @param {ReactNode} props.children - The children elements representing the individual tabs.
 * @param {string} props.className - Additional CSS class names for the tab list.
 * @param {number} props.selected - The index of the currently selected tab.
 * @param {function} props.onChange - A function to set the index of the selected tab.
 * @param {string} props.isActiveStyle - The CSS class name for the active tab style.
 * @returns {JSX.Element} - Returns the JSX element representing the TabList component.
 */
const TabList = ({
  children,
  className = "",
  parentClassName = "",
  selected,
  onChange,
  isActiveStyle = "",
  tabListStyle = "",
  tabCount = 1,
  isBottomRound = false,
  onTabSelected = () => {},
}) => {
  /**
   * For handling the tab click.
   *
   * @function
   * @param {number} index - The index of the currently selected tab.
   * @returns {void} - Returns void.
   */
  const onTabClick = (index) => {
    if (index === selected) return;
    onChange(index);
    onTabSelected?.(index);
  };

  return (
    <div className={`w-full overflow-x-scroll ${parentClassName}`} style={{ scrollbarWidth: "none" }}>
      <div className={`flex ${tabListStyle || ""}`} role="tablist">
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            id: index + 1,
            tabIndex: 0,
            onClick: () => onTabClick(index),
            isSelected: index === selected,
            classes: `${index === selected ? isActiveStyle || "border-t-4 border-darkOliveGreen bg-fnp-700" : "border-t-4 text-grey-400 border-white-900"} ${className} ${isBottomRound && index === selected ? "tabSelected" : ""} ${isBottomRound && 0 === selected ? "first" : ""} ${isBottomRound && tabCount - 1 === selected ? "last" : ""}`,
          });
        })}
      </div>
    </div>
  );
};

TabList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
  tabListStyle: PropTypes.string,
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isActiveStyle: PropTypes.string,
  tabCount: PropTypes.number,
  isBottomRound: PropTypes.bool,
  onTabSelected: PropTypes.func,
};

export default React.memo(TabList);
